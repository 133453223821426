import React from "react";
import { useSiteMetadata } from "../hooks/site-metadata";


/**
 * https://www.gatsbyjs.com/docs/how-to/adding-common-features/adding-seo-component/
 * @param param0 
 * @returns 
 */
export const SEO = ({ title, description, pathname, keywords, preventIndexing, children }: { title?: string, description?: string, pathname?: string, keywords?: string, preventIndexing?: boolean, children?: any }) => {

  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta content={seo.description} name='description' />
      <meta name="keywords" content={keywords} />
      <link rel='canonical' href={seo.url} />
      <meta name='robots' content='noarchive' />
      <meta name="image" content={seo.image} />

      {/* Open Graph */}
      <meta property='og:url' content={`${seo.url}`} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta name='image' property='og:image' content={seo.image} />
      <meta property='og:site_name' content={seo.title} />

      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content={seo.twitterUsername} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:url" content={seo.url} />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />

      {/* Favicons */}
      {/* {favicons.map((linkProps) => (
        <link key={linkProps.href} {...linkProps} />
      ))} */}
      <meta name='msapplication-TileColor' content='#f5f4f5' />
      <meta name='msapplication-TileImage' content={seo.image} />
      <meta name='theme-color' content='#222222' />

      { preventIndexing && (
        <>
          <meta name="robots" content="noindex"></meta>
          <meta name="googlebot" content="noindex"></meta>
        </>
      )}
      
      {children}
    </>
  )
}