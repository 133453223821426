import React, { useEffect, useState } from "react";
import { SEO } from "../components/seo";
import { isRunningOnWeb } from "../functions/utils";
import ToboComics from "./tobo-comics";
import { StaticQueryDocument, graphql, useStaticQuery } from "gatsby";

const IndexPage = () => {

  // --- STATES
  const [domLoaded, setDomLoaded] = useState<boolean>(false);
  // ---

  // --- Hook for fix Hydration Error
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  // ---

  // --- Hook for checking web o mobile
  useEffect(() => {
    /** Cosa Mistica senza prove del suo funzionamento */
    const userAgent = isRunningOnWeb() &&
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const isMobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    //Copiato da freename
    if (isMobile) {
      const vh = isRunningOnWeb() && window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
    /** Fine cosa Mistica senza prove del suo funzionamento */

  });
  

  return (
    <>
      { domLoaded && <ToboComics/> }     
    </>
  )

}

export default IndexPage;

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiTobo {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: any = seoData && useStaticQuery(seoData).strapiTobo.SEO;

  return (
    <SEO 
      title={seo.metaTitle} 
      description={seo.metaDescription} 
      keywords={seo.keywords} 
      preventIndexing={seo.preventIndexing} 
      pathname={seo.pathname}/>   
  )
}